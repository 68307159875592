<template>
  <b-card title="Hasta Bilgileri Düzenleme Sayfası">
    <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      :remote-operations="true"
      :show-row-lines="true"
      @exporting="onExporting"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :word-wrap-enabled="false"
      :selection="{ mode: 'single' }"
    >
      <DxGroupPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxFilterPanel :visible="true" />
      <DxSearchPanel :visible="true" />
      <DxFilterRow :visible="true" />
      <DxColumnFixing :enabled="true" />

      <DxPaging :enabled="true" />
      <DxEditing
        :allow-adding="false"
        :allow-updating="true"
        :allow-deleting="false"
        mode="popup"
      >
      </DxEditing>
      <DxExport :enabled="true" />

      <DxColumn
        data-field="id"
        caption="No"
        :allow-editing="false"
        width="150px"
        :visible="false"
      >
        <DxFormItem :visible="false" />
      </DxColumn>
      <DxColumn data-field="incoming" caption="Gelen Çağrı mı?" width="80px">
        <DxLookup
          :data-source="yakinData"
          value-expr="bool"
          display-expr="Name"
          width="120px"
        />
      </DxColumn>
      <DxColumn
        data-field="patient.firstName"
        caption="Hasta Adı"
        :allow-editing="false"
        width="150px"
      >
      </DxColumn>
      <DxColumn
        data-field="patient.lastName"
        caption="Hasta Soyadı"
        :allow-editing="false"
        width="150px"
      >
      </DxColumn>
      <DxColumn
        data-field="patient.identityNo"
        caption="Hasta TC"
        :allow-editing="false"
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="patient.phoneNumber"
        caption="Hasta Tel"
        :allow-editing="false"
        width="100px"
      >
      </DxColumn>

      <DxColumn
        data-field="dateCreated"
        caption="Oluşturma Tarihi"
        :allow-editing="false"
        dataType="datetime"
        format="dd/MM/yyyy HH:mm"
        sort-order="desc"
        width="140px"
      >
      </DxColumn>

      <DxColumn
        data-field="hospitalId"
        caption="Hastane"
        :allow-editing="false"
        width="150px"
      >
        <DxLookup
          :data-source="hospitalData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>

      <DxColumn
        data-field="departmentId"
        caption="Departman"
        :allow-editing="false"
        width="160px"
      >
        <DxLookup
          :data-source="departmentData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>

      <DxColumn
        data-field="quest.name"
        caption="Görevi"
        :allow-editing="false"
        width="200px"
      >
      </DxColumn>
      <DxColumn
        data-field="employee.fullName"
        caption="Görevli"
        :allow-editing="false"
        width="200px"
      >
      </DxColumn>

      <DxColumn data-field="discomfortId" caption="Rahatsızlığı" width="250px">
        <DxLookup
          :data-source="discomfortData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>

      <DxColumn
        data-field="contactChannel.name"
        caption="İletişim K. "
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="referanceChannelId"
        caption="Referans "
        width="100px"
      >
        <DxLookup
          :data-source="referansData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>

      <DxColumn
        data-field="treatmentDepartmentId"
        caption="Tedavi Bölümü "
        width="100px"
      >
        <DxLookup
          :data-source="treatmentData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>
      <DxColumn data-field="wantId" caption="Talebi " width="200px">
        <DxLookup :data-source="wantData" value-expr="id" display-expr="name" />
      </DxColumn>

      <DxColumn data-field="resultId" caption="Sonuç " width="150px">
        <DxLookup
          :data-source="resultData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>
      <DxColumn data-field="description" caption="Açıklama" width="250px">
      </DxColumn>

      <DxColumn
        data-field="isRelative"
        caption="Arayan Kendisi mi?"
        width="100px"
      >
        <DxLookup
          :data-source="yakinData"
          value-expr="bool"
          display-expr="Name"
        />
      </DxColumn>
      <DxColumn
        data-field="relativeFirstName"
        caption="Hasta Yakını Adı"
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="relativeLastName"
        caption="Hasta Yakını Soyadı"
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="createdBy"
        caption="Oluşturan"
        :allow-editing="false"
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="district.name"
        caption="İlçe"
        :allow-editing="false"
        width="100px"
      >
      </DxColumn>
      <DxColumn
        data-field="district.provinceId"
        caption="İl"
        :allow-editing="false"
        width="100px"
      >
        <DxLookup
          :data-source="provinceData"
          value-expr="id"
          display-expr="name"
        />
      </DxColumn>
    </DxDataGrid>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

import { exportDataGrid } from "devextreme/excel_exporter";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxScrolling,
  DxPaging,
  DxFormItem,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxCheckBox,
  DxExport,
  DxFilterPanel,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import globalSettings from "../settings";
const dataSource = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}callRequest/ReadByUserId`,
  insertUrl: `${globalSettings.baseURL}callRequest/Create`,
  updateUrl: `${globalSettings.baseURL}callRequest/Update`,
  deleteUrl: `${globalSettings.baseURL}callRequest/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});
const contactdata = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}ContactChannel/Read`,
  insertUrl: `${globalSettings.baseURL}ContactChannel/Create`,
  updateUrl: `${globalSettings.baseURL}ContactChannel/Update`,
  deleteUrl: `${globalSettings.baseURL}ContactChannel/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});
const provinceData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Province/Read`,
  insertUrl: `${globalSettings.baseURL}Province/Create`,
  updateUrl: `${globalSettings.baseURL}Province/Update`,
  deleteUrl: `${globalSettings.baseURL}Province/Delete`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});
const departmentData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Department/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const hospitalData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Hospital/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const referansData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}ReferanceChannel/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const treatmentData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}TreatmentDepartment/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});
const wantData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Want/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});
const discomfortData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Discomfort/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

const resultData = createStore({
  key: "id",
  loadUrl: `${globalSettings.baseURL}Result/Read`,
  onBeforeSend: (method, ajaxOptions) => {
    var accessToken = localStorage.getItem("accessToken");
    ajaxOptions.headers = {
      Authorization: "Bearer " + accessToken,
    };
  },
});

export default {
  components: {
    BCard,
    DxPaging,
    BCardText,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxFormItem,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxCheckBox,
    DxExport,
    DxFilterPanel,
    DxColumnFixing,
  },
  data() {
    return {
      dataSource,
      departmentData,
      hospitalData,
      referansData,
      treatmentData,
      wantData,
      discomfortData,
      resultData,
      provinceData,
      contactdata,

      yakinData: [
        {
          Name: "Evet",
          bool: true,
        },
        {
          Name: "Hayır",
          bool: false,
        },
      ],
      yakinagore: "",
    };
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Employees");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.landscape {
  width: 100%;
  height: 500px;
}
</style>
